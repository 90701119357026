import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import "./form.css";
import "../client/addClient.css";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router";

function Form() {
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email("Invalid Email Address")
        .required("* Field is required"),
      password: yup.string().required("* Field is required"),
    }),
    onSubmit: async (values) => {
      try {
        const res = await axios.post("https://api.violetholidays.com/login", {
          ...values,
        });
        // console.log(res.data.user_role);
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("role", res.data.user_role);
        localStorage.setItem("userid", res.data.user_id);
        history.push("/home");
        // console.log(res.data);
      } catch (e) {
        toast.error(e.response.data.message, { delay: 0 });
        // console.log(e.response.data);
      }
    },
  });

  return (
    <div className="formWrapper">
      <form onSubmit={formik.handleSubmit}>
        <div className="loginFormContainer">
          <div className="formItem">
            <input
              type="email"
              id="email"
              name="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              autoComplete="off"
              className={`login-form-control  ${
                formik.touched.email && formik.errors.email ? "form-error" : ""
              }`}
              placeholder="Email"
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="formErrorMsg">{formik.errors.email}</div>
            ) : null}
          </div>

          <div className="formItem">
            <input
              type="password"
              name="password"
              id="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              autoComplete="off"
              className={`login-form-control ${
                formik.touched.password && formik.errors.password
                  ? "form-error"
                  : ""
              }`}
              placeholder="Password"
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="formErrorMsg">{formik.errors.password}</div>
            ) : null}
          </div>
        </div>
        <div className="login-btn">
          <button type="submit" className="primary ">
            SIGN IN
          </button>
        </div>
      </form>
    </div>
  );
}

export default Form;
