import { useState } from "react";
import "./featuredInfo.css";
import axios from "axios";
import { useEffect } from "react";
import { toast } from "react-toastify";

function FeaturedInfo() {
  const userRoles = {
    admin: "admin",
    employee: "employee",
  };
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const userId = localStorage.getItem("userid");
  const [monthStats, setMonthStats] = useState();
  const [yearStats, setYearStats] = useState();
  const [empStats, setEmpStats] = useState();
  const [userStats, setUserStats] = useState();

  useEffect(() => fetchData(), []);

  const fetchData = async () => {
    if (role === userRoles.admin) {
      try {
        const res = await axios.get("https://api.violetholidays.com/stats", {
          headers: { Authorization: `Bearer ${token}` },
        });
        setMonthStats(res.data.monthStats);
        setYearStats(res.data.yearStats);
        if (res.data.empStats.length > 0) {
          setEmpStats(res.data.empStats[0].totalLeads);
        } else {
          setEmpStats(0);
        }
      } catch (e) {
        toast.error("Something Went Wrong", {});
      }
    } else {
      try {
        const result = await axios.get("https://api.violetholidays.com/stats", {
          headers: { Authorization: `Bearer ${token}` },
          params: { id: userId },
        });
        setUserStats(result.data.userStats);
      } catch (e) {
        toast.error("Something Went Wrong", {});
      }
    }
  };
  return (
    <div className="featured">
      <div className="featuredItem" style={{ backgroundColor: "#4af1b3" }}>
        <span className="featuredTitle">Total Clients Confirmed</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{monthStats ?? 0}</span>
        </div>
        <span className="featuredSub">This Month</span>
      </div>
      <div className="featuredItem" style={{ backgroundColor: "#2BC6B6ff" }}>
        <span className="featuredTitle">Total Clients Confirmed</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney">{yearStats ?? 0}</span>
        </div>
        <span className="featuredSub">This Year</span>
      </div>
      <div className="featuredItem" style={{ backgroundColor: "#FEA648ff" }}>
        <span className="featuredTitle">Total Clients Confirmed</span>
        <div className="featuredMoneyContainer">
          {role === userRoles.admin ? (
            <span className="featuredMoney">{empStats}</span>
          ) : (
            <span className="featuredMoney">{userStats}</span>
          )}
        </div>
        {role === userRoles.admin ? (
          <span className="featuredSub">By Employees</span>
        ) : (
          <span className="featuredSub">By You</span>
        )}
      </div>
    </div>
  );
}
export default FeaturedInfo;
