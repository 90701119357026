import React, { useEffect } from "react";
import { useHistory } from "react-router";
import "./login.css";
import Header from "./Header";
import Form from "./Form";
import { Link } from "react-router-dom";

function Login({ authenticated }) {
  const history = useHistory();
  useEffect(() => {
    if (authenticated) {
      history.push("/home");
    }
  });
  return (
    <div className="loginWrapper">
      <Header />
      <Form />
      <div className="forgotPassword">
        <Link to="/forgotpassword">Forgot Password ?</Link>
      </div>
    </div>
  );
}

export default Login;
