import React from "react";
import "./printModule.css";
import PrintOutlinedIcon from "@mui/icons-material/PrintOutlined";

import ReactToPrint from "react-to-print";

export default class Try extends React.Component {
  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
    // console.log(props.data);
  }

  render() {
    return (
      <>
        <ReactToPrint
          trigger={() => (
            <PrintOutlinedIcon
              style={{ marginLeft: "5px", cursor: "pointer" }}
            />
          )}
          content={() => this.componentRef.current}
          documentTitle={this.props.data.name + "_violet Holidays"}
          pageStyle={`
            @media print {
              .pagebreak {
                page-break-before: always;
              }
              .kashmir{
                height:100vh !important;
              }
            }
          `}
        />
        <div style={{ display: "none" }}>
          <div ref={this.componentRef} className="printWrapper">
            <div className="kashmir">
              <div className="logoWrapper">
                <img
                  alt="loog"
                  src="https://violetholidays.com/assets/logo.png"
                />
                <span className="logoTextt">
                  Registered with J&K Tourism Department
                </span>
              </div>
              <div className="clientInfoWrapper">
                <table className="infoTable">
                  <caption className="printTableCaption">
                    Client Details
                  </caption>
                  <tr>
                    <th>Name</th>
                    <td>{this.props.data.name}</td>
                  </tr>
                  <tr>
                    <th>Mobile Number</th>
                    <td>{this.props.data.mobile}</td>
                  </tr>
                  <tr>
                    <th>Trip Id</th>
                    <td>{this.props.data.tripId}</td>
                  </tr>
                  <tr>
                    <th>Arrival Date</th>
                    <td>{this.props.data.arrivalDate}</td>
                  </tr>
                  <tr>
                    <th>Departure Date</th>
                    <td>{this.props.data.departureDate}</td>
                  </tr>
                  <tr>
                    <th>Number of Persons</th>
                    <td>{this.props.data.persons}</td>
                  </tr>
                  {this.props.data.rooms !==null ?
                  <tr>
                    <th>Number of Rooms</th>
                    <td>{this.props.data.rooms}</td>
                  </tr>:""}
                  {this.props.data.extraBed ? (
                    <tr>
                      <th>Extra Bed</th>
                      <td>{this.props.data.extraBed}</td>
                    </tr>
                  ) : (
                    ""
                  )}
                  {this.props.data.cwob ? (
                    <tr>
                      <th>CWOB</th>
                      <td>{this.props.data.cwob}</td>
                    </tr>
                  ) : (
                    ""
                  )}
                  {this.props.data.mealPlan &&
                  <tr>
                    <th>Meal Plan</th>
                    <td>{this.props.data.mealPlan}</td>
                  </tr>}
                  {this.props.data.cab ? (
                    <tr>
                      <th>Cab</th>
                      <td>Yes</td>
                    </tr>
                  ) : (
                    ""
                  )}
                  {this.props.data.both ? (
                    <tr>
                      <th>Package & Cab</th>
                      <td>Yes</td>
                    </tr>
                  ) : (
                    ""
                  )}
                  {this.props.data.cabType ? (
                    <tr>
                      <th>Cab Type</th>
                      <td>{this.props.data.cabType}</td>
                    </tr>
                  ) : (
                    ""
                  )}

                  {this.props.data.driver ? (
                    <tr>
                      <th>Driver Details</th>
                      <td>{this.props.data.driver}</td>
                    </tr>
                  ) : (
                    ""
                  )}
                  <tr>
                    <th>Flight Details</th>
                    <td>{this.props.data.flight}</td>
                  </tr>
                  <tr>
                    <th>Pick Up</th>
                    <td>{this.props.data.pickUp}</td>
                  </tr>
                  <tr>
                    <th>Drop</th>
                    <td>{this.props.data.drop}</td>
                  </tr>
                  <tr>
                    <th>Confirmed By</th>
                    <td>{this.props.data.confirmedBy}</td>
                  </tr>

                  {this.props.data.source ? (
                    <tr>
                      <th>Source </th>
                      <td>{this.props.data.source}</td>
                    </tr>
                  ) : (
                    ""
                  )}
                  {this.props.data.inclusions ? (
                    <tr>
                      <th>Inclusions </th>
                      <td>{this.props.data.inclusions}</td>
                    </tr>
                  ) : (
                    ""
                  )}
                  {this.props.data.roomType ? (
                    <tr>
                      <th>Room Type </th>
                      <td>{this.props.data.roomType}</td>
                    </tr>
                  ) : (
                    ""
                  )}
                  <tr>
                    <th>Duration</th>
                    <td>
                      {Math.trunc(
                        (this.props.data.departure - this.props.data.arrival) /
                          (3600 * 24) +
                          1
                      )}{" "}
                      Days
                      {"    "}
                      {Math.trunc(
                        (this.props.data.departure - this.props.data.arrival) /
                          (3600 * 24)
                      )}{" "}
                      Nights
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            
            <div className="hotelInfoWrapper">
            {this.props.data.stayingDetails?.length > 0 &&
              <table className="infoTable">
                <caption className="printTableCaption">Staying Details</caption>
                <tr>
                  <th>Date</th>
                  <th>Hotel / Houseboat</th>
                  <th>Itenary</th>
                  <th>Nights</th>
                  <th>Confirmed</th>
                </tr>
                {this.props.data.stayingDetails.map((item, i) => {
                  return (
                    <tr>
                      <td>{item.date.split("T")[0]}</td>
                      <td>{item.hotel}</td>
                      <td>{item.itenary}</td>
                      <td>{item.nights}</td>
                      <td>{item.confirmed}</td>
                    </tr>
                  );
                })}
              </table>}
              <div className="payDetails">
                <table className="infoTable">
                  <tr>
                    <th>Total</th>
                    <td>{this.props.data.total}</td>
                  </tr>
                  <tr>
                    <th>Advance</th>
                    <td>{this.props.data.advance}</td>
                  </tr>
                  <tr>
                    <th>Balance</th>
                    <td>{this.props.data.total - this.props.data.advance}</td>
                  </tr>
                </table>
              </div>
              <div className="termsConditions">
                <p>Terms & Conditions</p>
                <ul>
                  <li>
                    Irrespective of circumstances 25% of total package cost is
                    non-refundable.
                  </li>
                  <li>Please call one day before arrival.</li>
                  <li>
                    If you feel any problems during the trip , kindly call us on
                    7006243656.
                  </li>
                  <li>
                    Remaining payment should be paid on arrival Ist Day
                  </li>
                </ul>
              </div>
              <div className="regards">
                <span className="logoTextt">With Warm Regards :</span>
                <span className="logoTextt">
                  {this.props.data.confirmedBy} -- Reservation Team
                </span>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
