import "./clientList.css";
import { DataGrid } from "@mui/x-data-grid";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import ClientModal from "../../components/modal/ClientModal";
import Tooltip from "@material-ui/core/Tooltip";
import ClearSharpIcon from "@mui/icons-material/ClearSharp";

function CompletedClients() {
  const userRoles = {
    admin: "admin",
    employees: "employees",
  };
  const user = localStorage.getItem("role");
  const token = localStorage.getItem("token");
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [clientData, setClientData] = useState();
  const [modalIsOpen, setIsOpen] = useState(false);

  const fetchData = async () => {
    try {
      const res = await axios.get("https://api.violetholidays.com/completed", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const check = res.data.clients.map(
        ({ _id, arrivalDate, departureDate, completed, ...rest }) => {
          return {
            id: _id,
            arrivalDate: new Date(arrivalDate * 1000).toDateString(),
            departureDate: new Date(departureDate * 1000).toDateString(),
            completed: completed.toString(),
            ...rest,
          };
        }
      );
      setData(check);
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (row) => {
    try {
      const res = await axios.delete(
        "https://api.violetholidays.com/clients/" + row.id,
        { headers: { Authorization: `Bearer ${token}` } },
        { data: row.id }
      );
      setData(data.filter((item) => item.email !== res.data.client.email));
      fetchData();
      toast.success(res.data.message, {});
    } catch (e) {
      toast.error(e.response.data.message, {});
    }
  };
  const handleOnClickCompleted = async (e, item) => {
    const updates = {
      id: item.id,
      completed: e.target.checked,
    };
    try {
      const res = await axios.put(
        "https://api.violetholidays.com/client",
        { ...updates },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      fetchData();
      toast.success(res.data.message, {});
    } catch (e) {
      toast.error(e.response.data.message, {});
    }
  };
  const handleView = async (row) => {
    setIsOpen(true);
    try {
      const res = await axios.get(
        "https://api.violetholidays.com/clients/" + row.id,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setClientData(res.data.client);
    } catch (e) {
      console.log(e);
    }
  };
  const onClose = (value) => {
    setIsOpen(value);
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
    // console.log(e.target.value);
  };

  const handleSearch = async () => {
    try {
      const result = await axios.get(
        "https://api.violetholidays.com/completed",
        {
          headers: { Authorization: `Bearer ${token}` },
          params: { q: search },
        }
      );
      // console.log(result.data);
      if (result.data.length === 0) {
        setSearch("");
        toast.error("No client found", {});
      } else {
        const check = result.data.map(
          ({ _id, arrivalDate, departureDate, ...rest }) => {
            return {
              id: _id,
              arrivalDate: new Date(arrivalDate * 1000).toDateString(),
              departureDate: new Date(departureDate * 1000).toDateString(),
              ...rest,
            };
          }
        );
        setData(check);
      }
    } catch (e) {}
  };
  const handleCancel = () => {
    setSearch("");
    fetchData();
  };

  const columns = [
    { field: "name", headerName: "Name", width: 140 },
    { field: "tripId", headerName: "Trip Id", width: 130 },
    { field: "arrivalDate", headerName: "Arrival Date", width: 150 },
    { field: "departureDate", headerName: "Departure Date", width: 150 },

    {
      field: "mobile",
      headerName: "Mobile Number",
      width: 150,
    },
    {
      field: "completed",
      headerName: "Completed",
      width: 110,
      align: "center",
    },
    {
      field: "changeStatus",
      headerName: "Change Status",
      align: "center",
      width: 140,
      renderCell: (params) => {
        // console.log(params);
        return (
          <span>
            <input
              type="checkbox"
              className="completed"
              checked={params.row.completed}
              onChange={(e) => handleOnClickCompleted(e, params.row)}
            />
          </span>
        );
      },
    },
    {
      field: "action",
      headerName: "Actions",
      width: 130,
      renderCell: (params) => {
        return (
          <>
            <button
              className="userListView"
              onClick={() => handleView(params.row)}
            >
              View
            </button>
            {user === userRoles.admin ? (
              <DeleteOutlineIcon
                className="userListDelete"
                onClick={() => handleDelete(params.row)}
              />
            ) : (
              ""
            )}
          </>
        );
      },
    },
  ];

  return (
    <div className="clientList">
      <div className="captionSearchWrapper">
        <span className="caption">Completed Clients</span>
        <div className="searchWrapper">
          <div className="searchInput">
            <input
              type="text"
              placeholder="Search"
              className="search"
              value={search}
              onChange={handleChange}
            />
            {search ? (
              <Tooltip title="Cancel Search">
                <ClearSharpIcon
                  onClick={handleCancel}
                  style={{ fontSize: "16px", cursor: "pointer" }}
                />
              </Tooltip>
            ) : (
              ""
            )}
          </div>
          <button className="searchBtn" onClick={handleSearch}>
            Search
          </button>
        </div>
      </div>
      <div className="dataGrid">
        <DataGrid
          rows={data}
          disableSelectionOnClick
          columns={columns}
          pageSize={7}
          rowsPerPageOptions={[7]}
        />
      </div>

      <ClientModal
        isOpen={modalIsOpen}
        onClose={onClose}
        title="Client Information"
        data={clientData}
      />
    </div>
  );
}

export default CompletedClients;
