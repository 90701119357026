import "../clientlist/clientList.css";
import "./employees.css";
import { DataGrid } from "@mui/x-data-grid";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ViewModal from "../../components/modal/ViewModal";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@material-ui/core/Tooltip";
import ClearSharpIcon from "@mui/icons-material/ClearSharp";

function EmployeeList() {
  const token = localStorage.getItem("token");
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [empData, setEmpData] = useState();
  const [modalIsOpen, setIsOpen] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const result = await axios.get(
        "https://api.violetholidays.com/employees",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const check = result.data.employees.map(({ _id, ...rest }, i) => {
        return { id: _id, ...rest };
      });
      // console.log(check);
      setData(check);
    } catch (e) {
      console.log(e);
    }
  };

  const handleDelete = async (row) => {
    // console.log(data);
    try {
      const res = await axios.delete(
        "https://api.violetholidays.com/employees/" + row.email,
        { headers: { Authorization: `Bearer ${token}` } },
        { data: row.email }
      );
      // console.log(res.data.employee);
      setData(data.filter((item) => item.email !== res.data.employee.email));
      toast.success("Employee Deleted Successfully");
    } catch (e) {
      toast.error("Somenthing went wrong");
    }
  };

  const handleView = async (row) => {
    setIsOpen(true);
    try {
      const res = await axios.get(
        "https://api.violetholidays.com/employees/" + row.email,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setEmpData(res.data.employee);
      // console.log(res.data.employee);
    } catch (e) {
      console.log(e);
    }
  };

  const onClose = (value) => {
    setIsOpen(value);
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
    // console.log(e.target.value);
  };

  const handleSearch = async () => {
    try {
      const result = await axios.get(
        "https://api.violetholidays.com/employees",
        {
          headers: { Authorization: `Bearer ${token}` },
          params: { q: search },
        }
      );
      // console.log(result.data);
      if (result.data.length === 0) {
        setSearch("");
        toast.error("No Employee found", {});
      } else {
        const check = result.data.map(
          ({ _id, arrivalDate, departureDate, ...rest }) => {
            return {
              id: _id,
              arrivalDate: new Date(arrivalDate * 1000).toDateString(),
              departureDate: new Date(departureDate * 1000).toDateString(),
              ...rest,
            };
          }
        );
        setData(check);
      }
    } catch (e) {}
  };
  const handleCancel = () => {
    setSearch("");
    fetchData();
  };

  const columns = [
    { field: "name", headerName: "Name", width: 200 },
    { field: "email", headerName: "Email", width: 210 },
    { field: "address", headerName: "Address", width: 200 },

    {
      field: "mobile",
      headerName: "Mobile Number",
      width: 170,
    },
    {
      field: "leads",
      headerName: "Clients Confirmed",
      width: 153,
      align: "center",
    },
    {
      field: "action",
      headerName: "Actions",
      width: 170,
      renderCell: (params) => {
        // console.log(params.row.completed);
        return (
          <>
            <button
              className="userListView"
              onClick={() => handleView(params.row)}
            >
              View
            </button>

            <DeleteOutlineIcon
              className="userListDelete"
              onClick={() => handleDelete(params.row)}
            />
          </>
        );
      },
    },
  ];

  return (
    <div className="clientList">
      <div className="captionSearchWrapper">
        <span className="caption">All Employees</span>
        <div className="searchWrapper">
          <div className="searchInput">
            <input
              type="text"
              placeholder="Search"
              className="search"
              value={search}
              onChange={handleChange}
            />
            {search ? (
              <Tooltip title="Cancel Search">
                <ClearSharpIcon
                  onClick={handleCancel}
                  style={{ fontSize: "16px", cursor: "pointer" }}
                />
              </Tooltip>
            ) : (
              ""
            )}
          </div>
          <button className="searchBtn" onClick={handleSearch}>
            Search
          </button>
        </div>
      </div>
      <div className="dataGrid">
        <DataGrid
          rows={data}
          disableSelectionOnClick
          columns={columns}
          pageSize={7}
          rowsPerPageOptions={[7]}
        />
        <div className="addEmpoyeeButton">
          <Link to="/addemployee">
            <Tooltip title="Add Employee">
              <Fab color="primary" aria-label="add">
                <AddIcon
                  style={{
                    color: "white",
                  }}
                />
              </Fab>
            </Tooltip>
          </Link>
        </div>
      </div>

      {modalIsOpen && (
        <ViewModal
          isOpen={modalIsOpen}
          onClose={onClose}
          data={empData}
          title="Employee Information"
        />
      )}
    </div>
  );
}

export default EmployeeList;
