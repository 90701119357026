import "./home.css";
import FeaturedInfo from "../../components/featuredinfo/FeaturedInfo";
import ClientData from "../../components/clientData/ClientData";
import { CalendarComponent } from "@syncfusion/ej2-react-calendars";

function Home() {
  const d = new Date().toDateString();
  return (
    <div className="home">
      <FeaturedInfo />
      <div className="userClientData">
        <div className="calendarComponent">
          <CalendarComponent value={d} min={d} max={d} />
        </div>
        <ClientData />
      </div>
    </div>
  );
}

export default Home;
