import "./sidebar.css";
import { Link } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import CancelPresentationOutlinedIcon from "@mui/icons-material/CancelPresentationOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import RestartAltOutlinedIcon from "@mui/icons-material/RestartAltOutlined";
import axios from "axios";
import { toast } from "react-toastify";

function Sidebar() {
  const userRoles = {
    admin: "admin",
    employee: "employee",
  };
  const token = localStorage.getItem("token");
  const user = localStorage.getItem("role");

  const handleReset = async () => {
    try {
      const res = await axios.put(
        "https://api.violetholidays.com/resetleads",
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      // console.log(res.data);
      toast.success(res.data.message, {});
    } catch (e) {
      // console.log(e);
      toast.error(e.response.data.message, {});
    }
  };

  return (
    <div className="sidebar">
      <div className="sidebarWrapper">
        <div className="logo">
          <img
            alt="logo"
            src="https://violetholidays.com/assets/logo.png"
            className="logo-img"
          />
          {/* <span
            style={{
              textAlign: "center",
              width: "100%",
              fontFamily: "Montserrat",
              fontWeight: "500",
            }}
          >
            Violet Holidays
          </span> */}
        </div>

        <div className="sidebarMenu">
          <ul className="sidebarList">
            <Link to="/home" className="links">
              <div className="nav-links">
                <HomeOutlinedIcon className="icons" />
                <li className="sidebarListItem">Home</li>
              </div>
            </Link>
            <Link to="/clients" className="links">
              <div className="nav-links">
                <PeopleOutlinedIcon className="icons" />
                <li className="sidebarListItem">Clients</li>
              </div>
            </Link>
            <Link to="/postclients" className="links">
              <div className="nav-links">
                <PeopleOutlinedIcon className="icons" />
                <li className="sidebarListItem">Post Clients</li>
              </div>
            </Link>
            {user === userRoles.admin ? (
              <Link to="/employees" className="links">
                <div className="nav-links">
                  <SupervisorAccountOutlinedIcon className="icons" />
                  <li className="sidebarListItem">Employees</li>
                </div>
              </Link>
            ) : (
              ""
            )}

            <Link to="/completed" className="links">
              <div className="nav-links">
                <AssignmentTurnedInOutlinedIcon className="icons" />
                <li className="sidebarListItem">Completed</li>
              </div>
            </Link>
            <Link to="/cancelled" className="links">
              <div className="nav-links">
                <CancelPresentationOutlinedIcon className="icons" />
                <li className="sidebarListItem">Cancelled</li>
              </div>
            </Link>
            {user === userRoles.admin ? (
              <div className="nav-links">
                <RestartAltOutlinedIcon className="icons" />
                <button
                  style={{ border: "none", backgroundColor: "transparent" }}
                  className="sidebarListItem"
                  onClick={handleReset}
                >
                  Reset Leads
                </button>
              </div>
            ) : (
              ""
            )}
          </ul>
        </div>
        <Link className="links" to="/" onClick={() => localStorage.clear()}>
          <div className="logoOutWrapper">
            <LogoutOutlinedIcon />
            <span className="sidebarListItem logoutLink">Logout</span>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default Sidebar;
