import Routers from "./routes/Routes";
import "./app.css";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router } from "react-router-dom";
function App() {
  return (
    <div className="app" style={{ minHeight: "100vh" }}>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
      <Router>
        <Routers />
      </Router>
    </div>
  );
}

export default App;
