import Modal from "react-modal";
import "./modal.css";
import ClearIcon from "@mui/icons-material/Clear";
import { Link } from "react-router-dom";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    height: "60%",
    width: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function ViewModal(props) {
  return (
    <div>
      <Modal isOpen={props.isOpen} style={customStyles}>
        <span>
          <ClearIcon
            style={{
              position: "absolute",
              top: "5px",
              right: "5px",
              fill: "red",
              height: "0.8em",
            }}
            onClick={() => props.onClose(false)}
          />
        </span>
        <div className="modalContainer">
          <h2 className="modalTitle">{props?.title}</h2>
          <table className="modalTable">
            <tr>
              <th>Name</th>
              <td>{props?.data?.name}</td>
            </tr>
            <tr>
              <th>Address</th>
              <td>{props?.data?.address}</td>
            </tr>
            <tr>
              <th>Email</th>
              <td>{props?.data?.email}</td>
            </tr>
            <tr>
              <th>Mobile Number</th>
              <td>{props?.data?.mobile}</td>
            </tr>
            <tr>
              <th>Clients Confirmed</th>
              <td>{props?.data?.leads}</td>
            </tr>
            <tr>
              <th>Role</th>
              <td>{props?.data?.role}</td>
            </tr>
          </table>
        </div>
        <Link
          to={{
            pathname: "/addemployee",
            state: { values: props.data, edit: true },
          }}
        >
          <button className="editButton">Edit</button>
        </Link>
      </Modal>
    </div>
  );
}
export default ViewModal;
