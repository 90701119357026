import Topbar from "../components/topbar/Topbar";
import Sidebar from "../components/sidebar/Sidebar";
import Home from "../pages/home/Home";
import ClientList from "../pages/clientlist/ClientList";
import AddClient from "../pages/client/AddClient";
import Login from "../pages/login/Login";
import ForgotPassword from "../pages/login/ForgotPassword";
import ResetPassword from "../pages/login/ResetPassword";
import AddEmployee from "../pages/employee/AddEmployee";
import CompletedClients from "../pages/clientlist/CompletedClients";
import CancelledClients from "../pages/clientlist/CancelledClients";
import PostClientList from "../pages/clientlist/PostClientList";
import { Switch, Route, useHistory } from "react-router-dom";
import ProtectedRoutes from "./ProtectedRoutes";
import EmployeeList from "../pages/employeelist/employees";
import { useEffect, useState } from "react";

const Routers = () => {
  const history = useHistory();
  const [authenticated, setAuthenticated] = useState(false);
  const token = localStorage.getItem("token");
  useEffect(() => {
    // console.log(history);

    history.listen((location, action) => {
      const token = localStorage.getItem("token");
      if (action === "PUSH") {
        if (token) {
          setAuthenticated(true);
        } else {
          setAuthenticated(false);
        }
      }
      // console.log(location, action, "HII");
    });
    if (token) {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }
  }, []);
  const userRoles = {
    admin: "admin",
    employee: "employee",
  };
  const user = localStorage.getItem("role");
  return (
    <Switch>
      <Route exact path="/">
        <Login authenticated={authenticated} />
      </Route>
      <Route exact path="/forgotpassword">
        <ForgotPassword />
      </Route>
      <Route exact path="/resetpassword/:token">
        <ResetPassword />
      </Route>

      <div className="container">
        {authenticated ? (
          <>
            {" "}
            <Sidebar />
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Topbar />
              <ProtectedRoutes exact path="/home" component={Home} />
              <ProtectedRoutes exact path="/clients" component={ClientList} />
              <ProtectedRoutes
                exact
                path="/postclients"
                component={PostClientList}
              />
              <ProtectedRoutes
                exact
                path="/completed"
                component={CompletedClients}
              />
              <ProtectedRoutes
                exact
                path="/cancelled"
                component={CancelledClients}
              />
              <ProtectedRoutes exact path="/addclient" component={AddClient} />
              {user === userRoles.admin ? (
                <ProtectedRoutes
                  exact
                  path="/employees"
                  component={EmployeeList}
                />
              ) : (
                ""
              )}
              {user === userRoles.admin ? (
                <ProtectedRoutes
                  exact
                  path="/addemployee"
                  component={AddEmployee}
                />
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </Switch>
  );
};

export default Routers;
