import { React, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import "../client/addClient.css";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router";

function AddEmployee(props) {
  const [data, setData] = useState();
  const [edit, setEdit] = useState();
  const history = useHistory();

  useEffect(() => {
    setData(props?.location?.state?.values);
    setEdit(props?.location?.state?.edit);
  }, [props?.location?.state?.values, props?.location?.state?.edit]);

  const token = localStorage.getItem("token");

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: data?.name || "",
      email: data?.email || "",
      password: data?.password || "",
      address: data?.address || "",
      mobile: data?.mobile || "",
      role: data?.role,
    },
    validationSchema: yup.object({
      name: yup
        .string()
        .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field ")
        .max(30, "Name Shoulb be 30 characters or less")
        .min(3, "Name should be 3 characters or more")
        .required("* Feild is required"),
      email: yup
        .string()
        .email("Invalid Email Address")
        .required("* Field is required"),
      password: yup
        .string()
        .required("* Field is required")
        .min(8, "Password must be atleast 6 characters"),
      address: yup
        .string()
        .min(3, "Name should be 3 characters or more")
        .required("* Feild is required"),
      mobile: yup
        .string()
        .matches(/^[0-9]+$/, "Must be only digits")
        .min(10, "Mobile min number should contain 10 characters")
        .max(10, "Mobile max number should contain 10 characters")
        .required("* Feild is required"),
      role: yup.string().required("* Feild is required"),
    }),
    onSubmit: async (values) => {
      if (!edit) {
        try {
          const res = await axios.post(
            "https://api.violetholidays.com/addemployee",
            {
              ...values,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          toast.success(res.data.message, {});
          formik.resetForm();
          setTimeout(() => {
            history.push("/employees");
          }, 1000);
        } catch (e) {
          toast.error(e.response.data.message, {});
        }
      } else {
        try {
          const res = await axios.put(
            "https://api.violetholidays.com/addemployee",
            {
              ...values,
            },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          toast.success(res.data.message, {});
          setTimeout(() => {
            history.push("/employees");
          }, 1000);
        } catch (e) {
          toast.error(e.response.data.message, {});
        }
      }
    },
  });

  useEffect(() => {
    if (!formik.isSubmitting) return;
    if (Object.keys(formik.errors).length > 0) {
      document.getElementsByName(Object.keys(formik.errors)[0])[0].focus();
    }
  }, [formik]);

  return (
    <div className="addClient">
      <div className="addClientContainer">
        <div className="addClientTitle">
          {edit ? "Edit Employee" : "Add Employee"}
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="loginFormContainer">
            <div className="formItem">
              <input
                type="text"
                id="name"
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                autoComplete="off"
                className={`form-control ${
                  formik.touched.name && formik.errors.name ? "form-error" : ""
                }`}
                placeholder="Name"
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="formErrorMsg">{formik.errors.name}</div>
              ) : null}
            </div>
            <div className="formItem">
              <input
                type="email"
                id="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                autoComplete="off"
                className={`form-control  ${
                  formik.touched.email && formik.errors.email
                    ? "form-error"
                    : ""
                }`}
                placeholder="Email"
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="formErrorMsg">{formik.errors.email}</div>
              ) : null}
            </div>

            <div className="formItem">
              <input
                type="password"
                name="password"
                id="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                autoComplete="off"
                className={`form-control ${
                  formik.touched.password && formik.errors.password
                    ? "form-error"
                    : ""
                }`}
                placeholder="Password"
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="formErrorMsg">{formik.errors.password}</div>
              ) : null}
            </div>
            <div className="formItem">
              <input
                type="text"
                id="address"
                name="address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address}
                autoComplete="off"
                className={`form-control  ${
                  formik.touched.address && formik.errors.address
                    ? "form-error"
                    : ""
                }`}
                placeholder="Address"
              />
              {formik.touched.address && formik.errors.address ? (
                <div className="formErrorMsg">{formik.errors.address}</div>
              ) : null}
            </div>
            <div className="formItem">
              <input
                type="number"
                name="mobile"
                id="mobile"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.mobile}
                autoComplete="off"
                className={`form-control ${
                  formik.touched.mobile && formik.errors.mobile
                    ? "form-error"
                    : ""
                }`}
                placeholder="Mobile Number"
              />
              {formik.touched.mobile && formik.errors.mobile ? (
                <div className="formErrorMsg">{formik.errors.mobile}</div>
              ) : null}
            </div>
            <div className="formItem">
              <select
                name="role"
                id="role"
                value={formik.values.role}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={`form-control ${
                  formik.touched.role && formik.errors.role ? "form-error" : ""
                }`}
                style={{
                  backgroundColor: "#fff",
                  color: "#74757a",
                }}
              >
                <option value="" label="Select a user role" />
                <option value="admin" label="Admin" />
                <option value="employee" label="Employee" />
              </select>
              {formik.touched.role && formik.errors.role ? (
                <div className="formErrorMsg">{formik.errors.role}</div>
              ) : null}
            </div>
            <div className="">
              <button type="submit" className="primary ">
                {edit ? "Update" : "Submit"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default AddEmployee;
