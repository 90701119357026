import "./clientList.css";
import { format } from "date-fns";
import { DataGrid } from "@mui/x-data-grid";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Tooltip from "@material-ui/core/Tooltip";
import ClientModal from "../../components/modal/ClientModal";
import ClearSharpIcon from "@mui/icons-material/ClearSharp";

import Try from "./Try";

function PostClientList() {
  const userRoles = {
    admin: "admin",
    employee: "employee",
  };
  const token = localStorage.getItem("token");
  const user = localStorage.getItem("role");
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [clientData, setClientData] = useState();
  const [modalIsOpen, setIsOpen] = useState(false);

  const fetchData = async () => {
    try {
      const res = await axios.get(
        "https://api.violetholidays.com/postclients",
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const check = res.data.clients.map(
        ({ _id, arrivalDate, departureDate, ...rest }) => {
          const arr_date = format(new Date(arrivalDate * 1000), "dd/MM/yyyy");
          const dep_date = format(new Date(departureDate * 1000), "dd/MM/yyyy");
          return {
            id: _id,
            arrivalDate: arr_date,
            departureDate: dep_date,
            arrival: arrivalDate,
            departure: departureDate,
            ...rest,
          };
        }
      );
      // console.log(res);
      if (res.status === 404) {
        toast.error(res.data.message);
      } else {
        setData(check);
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleDelete = async (row) => {
    try {
      const res = await axios.delete(
        "https://api.violetholidays.com/clients/" + row.id,
        { headers: { Authorization: `Bearer ${token}` } },
        { data: row.id }
      );
      setData(data.filter((item) => item.email !== res.data.client.email));
      fetchData();
      toast.success(res.data.message, {});
    } catch (e) {
      toast.error(e.response.data.message, {});
    }
  };

  const handleOnClickCancelled = async (e, item) => {
    const updates = {
      id: item.id,
      cancelled: e.target.checked,
    };
    try {
      const res = await axios.put(
        "https://api.violetholidays.com/client",
        { ...updates },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      fetchData();
      toast.success(res.data.message, {});
    } catch (e) {
      toast.error(e.response.data.message, {});
    }
  };

  const handleOnClickCompleted = async (e, item) => {
    const updates = {
      id: item.id,
      completed: e.target.checked,
    };
    try {
      const res = await axios.put(
        "https://api.violetholidays.com/client",
        { ...updates },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      fetchData();
      toast.success(res.data.message, {});
    } catch (e) {
      toast.error(e.response.data.message, {});
    }
  };

  const handleView = async (row) => {
    console.log(row);
    setIsOpen(true);
    try {
      const res = await axios.get(
        "https://api.violetholidays.com/clients/" + row.id,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setClientData(res.data.client);
    } catch (e) {
      console.log(e);
    }
  };
  const onClose = (value) => {
    setIsOpen(value);
  };

  const handleChange = async (e) => {
    setSearch(e.target.value);
  };
  const searchData = async () => {
    try {
      const result = await axios.get("http://localhost:5000/postclients", {
        headers: { Authorization: `Bearer ${token}` },
        params: { q: search },
      });
      if (result.data.length === 0) {
        setSearch("");
        toast.error("No client found", {});
      } else {
        const check = result.data.map(
          ({ _id, arrivalDate, departureDate, ...rest }) => {
            const arr_date = format(new Date(arrivalDate * 1000), "dd/MM/yyyy");
            const dep_date = format(
              new Date(departureDate * 1000),
              "dd/MM/yyyy"
            );
            return {
              id: _id,
              arrivalDate: arr_date,
              departureDate: dep_date,
              arrival: arrivalDate,
              departure: departureDate,
              ...rest,
            };
          }
        );
        setData(check);
      }
    } catch (e) {
      // console.log(e);
    }
  };

  const handleCancel = () => {
    // alert("hello");
    setSearch("");
    fetchData();
  };

  const columns = [
    { field: "name", headerName: "Name", width: 150 },
    { field: "tripId", headerName: "Trip Id", width: 120 },
    { field: "arrivalDate", headerName: "Arrival Date", width: 150 },
    { field: "departureDate", headerName: "Departure Date", width: 150 },

    {
      field: "mobile",
      headerName: "Mobile Number",
      width: 150,
    },
    {
      field: "completed",
      headerName: "Completed",
      width: 113,
      align: "center",
      renderCell: (params) => {
        return (
          <span>
            <input
              type="checkbox"
              className="completed"
              checked={params.row.completed}
              onChange={(e) => {
                handleOnClickCompleted(e, params.row);
              }}
            />
          </span>
        );
      },
    },
    {
      field: "cancelled",
      headerName: "Cancelled",
      align: "center",
      width: 110,
      renderCell: (params) => {
        // console.log(params);
        return (
          <span>
            <input
              type="checkbox"
              className="cancelled"
              checked={params.row.cancelled}
              onChange={(e) => handleOnClickCancelled(e, params.row)}
            />
          </span>
        );
      },
    },
    {
      field: "action",
      headerName: "Actions",
      width: 160,
      renderCell: (params) => {
        // console.log("params", params);
        return (
          <>
            <button
              className="userListView"
              onClick={() => handleView(params.row)}
            >
              View
            </button>

            {user === userRoles.admin ? (
              <DeleteOutlineIcon
                className="userListDelete"
                onClick={() => handleDelete(params.row)}
              />
            ) : (
              ""
            )}

            <Try data={params.row} />
          </>
        );
      },
    },
  ];

  return (
    <div className="clientList">
      <div className="captionSearchWrapper">
        <span className="caption">All Clients</span>
        <div className="searchWrapper">
          <div className="searchInput">
            <input
              type="text"
              className="search"
              placeholder="Search"
              value={search}
              onChange={handleChange}
            />
            {search ? (
              <Tooltip title="Cancel Search">
                <ClearSharpIcon
                  style={{ fontSize: "16px", cursor: "pointer" }}
                  onClick={handleCancel}
                />
              </Tooltip>
            ) : (
              ""
            )}
          </div>
          <button className="searchBtn" onClick={searchData}>
            Search
          </button>
        </div>
      </div>
      <div className="dataGrid">
        <DataGrid
          rows={data}
          disableSelectionOnClick
          columns={columns}
          pageSize={7}
          rowsPerPageOptions={[7]}
        />
        <div
          className="addClientButton"
          style={{
            borderRadius: "100%",
          }}
        >
          <Link to="/addclient">
            <Tooltip title="Add Client">
              <Fab color="primary" aria-label="add">
                <AddIcon
                  style={{
                    color: "white",
                  }}
                />
              </Fab>
            </Tooltip>
          </Link>
        </div>
      </div>

      <ClientModal
        isOpen={modalIsOpen}
        onClose={onClose}
        title="Client Information"
        data={clientData}
      />
    </div>
  );
}

export default PostClientList;
