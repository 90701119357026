import React, { useEffect, useState } from "react";
import "./clientdata.css";
import axios from "axios";
import { format } from "date-fns";

function ClientData() {
  const user_roles = {
    admin: "admin",
    employee: "employee",
  };
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userid");
  const role = localStorage.getItem("role");

  // console.log(id);

  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    if (role === user_roles.admin) {
      try {
        const res = await axios.get(
          "https://api.violetholidays.com/comingclients",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setData(res.data.upComingClients);
        //   console.log(res.data.upComingClients);
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        const result = await axios.get(
          "https://api.violetholidays.com/comingclients",
          {
            headers: { Authorization: `Bearer ${token}` },
            params: { id: userId },
          }
        );
        setData(result.data.upComingClients);
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <div className="tableData">
      <table className="clientTable">
        <caption className="tableCaption">Upcoming Clients</caption>
        <tr>
          <th>Name</th>
          <th>Trip ID</th>
          <th>Arrival Date</th>
          <th>Departure Date</th>
          <th>Mobile Number</th>
        </tr>
        {/* {console.log(data)} */}
        {data.map((item) => {
          return (
            <tr>
              <td>{item.name}</td>
              <td>{item.tripId}</td>
              <td>{format(new Date(item.arrivalDate * 1000), "dd/MM/yyyy")}</td>
              <td>
                {format(new Date(item.departureDate * 1000), "dd/MM/yyyy")}
              </td>
              <td>{item.mobile}</td>
            </tr>
          );
        })}
      </table>
    </div>
  );
}

export default ClientData;
