import "./hoteldetails.css";
import { useEffect, useState } from "react";
import MaterialTable from "material-table";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import LastPageIcon from "@mui/icons-material/LastPage";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import FirstPageIcon from "@mui/icons-material/FirstPage";

function HotelDetails(props) {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([
    {
      title: "Date",
      field: "date",
      align: "left",
      type: "date",
      validate: (rowData) =>
        rowData.hotel === undefined
          ? { isValid: false, helperText: "* Required" }
          : true,
    },
    {
      title: "Hotel / Houseboat",
      field: "hotel",
      align: "left",
      validate: (rowData) => {
        if (rowData.hotel === undefined || rowData.hotel === "") {
          return "* Required";
        } else if (rowData.hotel.length < 3) {
          return "Atleast 3 characters";
        } else {
          return true;
        }
      },
    },
    {
      title: "Itenary",
      field: "itenary",
      align: "left",
      validate: (rowData) => {
        if (rowData.itenary === undefined || rowData.itenary === "") {
          return "* Required";
        } else if (rowData.itenary.length < 3) {
          return "Atleast 3 characters";
        } else {
          return true;
        }
      },
    },
    {
      title: "Nights",
      field: "nights",
      align: "left",
      type: "number",
      validate: (rowData) =>
        rowData.nights === undefined
          ? { isValid: false, helperText: "* Required" }
          : true,
    },
    {
      title: "Confirmed",
      field: "confirmed",
      align: "left",
      validate: (rowData) =>
        rowData.confirmed === undefined
          ? { isValid: false, helperText: "* Required" }
          : true,
    },
  ]);

  useEffect(() => {
    console.log("bobai");
    if (props.data) {
      setData(props.data);
    }
  }, [props.data]);

  return (
    <div className="hotelDetails">
      <MaterialTable
        title="Hotel Details"
        columns={columns}
        data={data}
        options={{
          search: false,
          sorting: false,
          filtering: false,
          addRowPosition: "first",
          paging: true,
          headerStyle: {
            backgroundColor: "#F9CCDD",
            color: "#000",
            padding: "5px",
          },
          // showEmptyDataSourceMessage: false,
        }}
        icons={{
          Add: () => (
            <button
              type="button"
              style={{
                fontSize: "16px",
                padding: "0 16px ",
                fontweight: "700",
                backgroundColor: "#1b69e5",
                color: "white",
                border: "none",
                borderRadius: "6px",
                lineHeight: "35px",
              }}
            >
              Add Hotel
            </button>
          ),
          Delete: () => <DeleteOutlineIcon style={{ fill: "red" }} />,
          Edit: () => <EditIcon style={{ fill: "#1b69e5" }} />,
          Clear: () => <ClearIcon style={{ fill: "red" }} />,
          Check: () => <DoneAllIcon style={{ fill: "#1b69e5" }} />,
          NextPage: () => <ArrowForwardIosIcon />,
          LastPage: () => <LastPageIcon />,
          PreviousPage: () => <ArrowBackIos />,
          FirstPage: () => <FirstPageIcon />,
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                if (props.onEdit) {
                  props.onEdit([...data, newData]);
                  setData([...data, newData]);
                }

                resolve();
              }, 800);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataUpdate = [...data];
                const index = oldData.tableData.id;
                dataUpdate[index] = newData;
                setData([...dataUpdate]);

                if (props.onEdit) {
                  props.onEdit([...dataUpdate]);
                }

                resolve();
              }, 800);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataDelete = [...data];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                setData([...dataDelete]);

                if (props.onEdit) {
                  props.onEdit([...dataDelete]);
                }

                resolve();
              }, 600);
            }),
        }}
      />
    </div>
  );
}
export default HotelDetails;
