import "./addClient.css";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import HotelDetails from "./HotelDetails";
import * as Yup from "yup";
import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import { toast } from "react-toastify";
import { Formik } from "formik";

function AddClient(props) {
  const history = useHistory();
  const [data, setData] = useState();
  const [edit, setEdit] = useState();
  const [userData, setUserData] = useState([]);
  const [loader, setLoader] = useState(true);
  const token = localStorage.getItem("token");

  useEffect(() => {
    setData(props?.location?.state?.values);
    setEdit(props?.location?.state?.edit);
  }, [props?.location?.state?.values, props?.location?.state?.edit]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const res = await axios.get("https://api.violetholidays.com/employees", {
        headers: { Authorization: `Bearer ${token}` },
      });
      // console.log(res.data.employees);
      setUserData(res.data.employees);
    } catch (e) {
      toast.error("Something went wrong", {});
    }
  };

  return (
    <Formik
      enableReinitialize={loader}
      initialValues={{
        id: data?._id || "",
        name: data?.name || "",
        mobile: data?.mobile || "",
        tripId: data?.tripId || "",
        arrivalDate: data?.arrivalDate || new Date().getTime() / 1000,
        departureDate: data?.departureDate || new Date().getTime() / 1000,
        persons: data?.persons || "",
        rooms: data?.rooms || "",
        extraBed: data?.ex || "",
        cwob: data?.cwob || "",
        mealPlan: data?.mealPlan,
        cab: Boolean(data?.cab) || false,
        both:Boolean(data?.both) || false,
        cabType: data?.cabType || "",
        driver: data?.driver || "",
        flight: data?.flight || "",
        pickUp: data?.pickUp || "",
        drop: data?.drop || "",
        confirmedBy: data?.confirmedBy || "",
        source: data?.source || "",
        inclusions: data?.inclusions || "",
        roomType: data?.roomType || "",
        stayingDetails: data?.stayingDetails || [],
        total: data?.total ?? 0,
        advance: data?.advance ?? 0,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .matches(
            /^[aA-zZ\s.]+$/,
            "Only alphabets are allowed for this field "
          )
          .max(30, "Name Shoulb be 30 characters or less")
          .min(3, "Name should be 3 characters or more")
          .required("* Feild is required"),
        mobile: Yup.string()
          .matches(/^[0-9]+$/, "Must be only digits")
          .min(10, "Mobile min number should contain 10 characters")
          .max(10, "Mobile max number should contain 10 characters")
          .required("* Feild is required"),
        tripId: Yup.string().required("* Feild is required"),
        arrivalDate: Yup.date().required("* Feild is required"),
        departureDate: Yup.date().required("* Feild is required"),
        persons: Yup.string().required("* Feild is required"),
        rooms: Yup.number()
          .positive("Numbers of rooms must be positive"),
        extraBed: Yup.number(),
        cwob: Yup.number(),
        mealPlan: Yup.string(),
        flight: Yup.string().required("* Feild is required"),
        pickUp: Yup.string().required("* Feild is required"),
        drop: Yup.string().required("* Feild is required"),
        total: Yup.number()
          .positive("Numbers of Persons must be positive")
          .required("* Feild is required"),
        advance: Yup.number().required("* Feild is required"),
      })}
      onSubmit={async (values) => {
        // console.log(values);
        setLoader(false);
        // console.log(edit);
        if (!edit) {
          try {
            const res = await axios.post(
              "https://api.violetholidays.com/client",
              {
                ...values,
              },
              { headers: { Authorization: `Bearer ${token}` } }
            );
            // console.log(res.data);
            toast.success(res.data.message, {});
            setTimeout(() => {
              history.push("/clients");
            }, 1000);
          } catch (e) {
            // console.log(e);
            toast.error(e.response.data.message, {});
          }
        } else {
          try {
            const res = await axios.put(
              "https://api.violetholidays.com/client",
              { ...values },
              { headers: { Authorization: `Bearer ${token}` } }
            );
            // console.log(res.data);
            toast.success(res.data.message, {});
            setTimeout(() => {
              history.push("/clients");
            }, 1000);
          } catch (e) {
            console.log(e);
            // toast.error(e.response.data.message, {});
            setTimeout(() => {
              history.push("/clients");
            }, 1000);
          }
        }
      }}
      render={({
        errors,
        touched,
        values,
        handleBlur,
        handleSubmit,
        handleChange,
        setFieldValue,
      }) => (
        <div className="addClient">
          <div className="addClientContainer">
            <div className="addClientTitle">
              {edit ? "Edit Client" : "Add Client"}
            </div>
            <form onSubmit={handleSubmit}>
              <div className="formContainer">
                <div className="formItem">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    autoComplete="off"
                    className={`form-control ${
                      touched.name && errors.name ? "form-error" : ""
                    }`}
                    placeholder="Name"
                  />
                  {touched.name && errors.name ? (
                    <div className="formErrorMsg">{errors.name}</div>
                  ) : null}
                </div>
                <div className="formItem">
                  <input
                    type="number"
                    name="mobile"
                    id="mobile"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.mobile}
                    autoComplete="off"
                    className={`form-control ${
                      touched.mobile && errors.mobile ? "form-error" : ""
                    }`}
                    placeholder="Mobile Number"
                  />
                  {touched.mobile && errors.mobile ? (
                    <div className="formErrorMsg">{errors.mobile}</div>
                  ) : null}
                </div>
                <div className="formItem">
                  <input
                    type="text"
                    id="tripId"
                    name="tripId"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.tripId}
                    autoComplete="off"
                    className={`form-control ${
                      touched.tripId && errors.tripId ? "form-error" : ""
                    }`}
                    placeholder="Trip ID"
                  />
                  {touched.tripId && errors.tripId ? (
                    <div className="formErrorMsg">{errors.tripId}</div>
                  ) : null}
                </div>
                <div className="formItem">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      className={`form-control ${
                        touched.arrivalDate && errors.arrivalDate
                          ? "form-error"
                          : ""
                      }`}
                      id="arrivalDate"
                      name="arrivalDate"
                      label="Arrival Date"
                      minDate={new Date()}
                      value={new Date(values.arrivalDate * 1000)}
                      onChange={(d) => {
                        setFieldValue(
                          "arrivalDate",
                          new Date(d).getTime() / 1000
                        );
                      }}
                      onBlur={handleBlur}
                    />
                  </MuiPickersUtilsProvider>
                  {touched.arrivalDate && errors.arrivalDate ? (
                    <div className="formErrorMsg">{errors.arrivalDate}</div>
                  ) : null}
                </div>
                <div className="formItem">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      className={`form-control ${
                        touched.departureDate && errors.departureDate
                          ? "form-error"
                          : ""
                      }`}
                      id="departureDate"
                      name="departureDate"
                      label="Departure Date"
                      minDate={new Date()}
                      value={new Date(values.departureDate * 1000)}
                      onChange={(d) => {
                        setFieldValue(
                          "departureDate",
                          new Date(d).getTime() / 1000
                        );
                      }}
                      onBlur={handleBlur}
                    />
                  </MuiPickersUtilsProvider>
                  {touched.departureDate && errors.departureDate ? (
                    <div className="formErrorMsg">{errors.departureDate}</div>
                  ) : null}
                </div>
                <div className="formItem">
                  <input
                    type="text"
                    id="persons"
                    name="persons"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.persons}
                    autoComplete="off"
                    className={`form-control ${
                      touched.persons && errors.persons ? "form-error" : ""
                    }`}
                    placeholder="Number Of Persons"
                  />
                  {touched.persons && errors.persons ? (
                    <div className="formErrorMsg">{errors.persons}</div>
                  ) : null}
                </div>
                <div className="formItem">
                  <input
                    type="number"
                    id="rooms"
                    name="rooms"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.rooms}
                    autoComplete="off"
                    className={`form-control ${
                      touched.rooms && errors.rooms ? "form-error" : ""
                    }`}
                    disabled={values.cab && !values.both}
                    placeholder="Number Of Rooms"
                  />
                  {touched.rooms && errors.rooms ? (
                    <div className="formErrorMsg">{errors.rooms}</div>
                  ) : null}
                </div>
                <div className="formComponentWrapper">
                  <div className="formItem">
                    <input
                      type="number"
                      id="extraBed"
                      name="extraBed"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.extraBed}
                      autoComplete="off"
                      className={`form-control ${
                        touched.extraBed && errors.extraBed ? "form-error" : ""
                      }`}
                      disabled={values.cab && !values.both}
                      placeholder="Extra Bed"
                    />
                    {touched.extraBed && errors.extraBed ? (
                      <div className="formErrorMsg">{errors.extraBed}</div>
                    ) : null}
                  </div>
                  <div className="formItem">
                    <input
                      type="number"
                      id="cwob"
                      name="cwob"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.cwob}
                      autoComplete="off"
                      className={`form-control ${
                        touched.cwob && errors.cwob ? "form-error" : ""
                      }`}
                      disabled={values.cab && !values.both}
                      placeholder="Child Without Bed"
                    />
                    {touched.cwob && errors.cwob ? (
                      <div className="formErrorMsg">{errors.cwob}</div>
                    ) : null}
                  </div>
                </div>
                <div className="formItem">
                  <select
                    name="mealPlan"
                    id="mealPlan"
                    value={values.mealPlan}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={values.cab && !values.both}
                    className={`form-control ${
                      touched.mealPlan && errors.mealPlan ? "form-error" : ""
                    }`}
                    style={{
                      backgroundColor: "#fff",
                      color: "#74757a",
                    }}
                  >
                    <option value="" label="Select a meal plan" />
                    <option value="CP" label="CP" />
                    <option value="EP" label="EP" />
                    <option value="MAP" label="MAP" />
                  </select>
                  {touched.mealPlan && errors.mealPlan ? (
                    <div className="formErrorMsg">{errors.mealPlan}</div>
                  ) : null}
                </div>
                <div className="formComponentWrapper">
                  <div className="formItemWrapper">
                  <div className="formItem">
                    <div
                      className={`customControl form-control ${
                        touched.cab && errors.cab ? "form-error" : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        name="cab"
                        id="cab"
                        checked={values.cab}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.cab}
                        disabled={values.both}
                      />
                      <label htmlFor="cab" className="custom-label">
                        Cab Facility
                      </label>
                    </div>
                    {touched.cab && errors.cab ? (
                      <div className="formErrorMsg">{errors.cab}</div>
                    ) : null}
                  </div>
                  <div className="formItem">
                    <div
                      className={`customControl form-control ${
                        touched.cab && errors.cab ? "form-error" : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        name="both"
                        id="both"
                        checked={values.both}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.both}
                        disabled={values.cab}
                      />
                      <label htmlFor="both" className="custom-label">
                        Package & Cab
                      </label>
                    </div>
                    {touched.cab && errors.cab ? (
                      <div className="formErrorMsg">{errors.both}</div>
                    ) : null}
                  </div>
                  </div>
                  <div className="formItem">
                    <input
                      type="text"
                      id="cabType"
                      name="cabType"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.cabType}
                      autoComplete="off"
                      disabled={!values.both && !values.cab}
                      className={`form-control ${
                        touched.cabType && errors.cabType ? "form-error" : ""
                      }`}
                      placeholder="Cab Type"
                    />
                    {touched.cabType && errors.cabType ? (
                      <div className="formErrorMsg">{errors.cabType}</div>
                    ) : null}
                  </div>
                </div>
                <div className="formItem">
                  <input
                    type="text"
                    id="driver"
                    name="driver"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.driver}
                    autoComplete="off"
                    disabled={!values.both && !values.cab}
                    className={`form-control ${
                      touched.driver && errors.driver ? "form-error" : ""
                    }`}
                    placeholder="Driver Details"
                  />
                  {touched.driver && errors.driver ? (
                    <div className="formErrorMsg">{errors.driver}</div>
                  ) : null}
                </div>
                <div className="formItem">
                  <input
                    type="text"
                    id="flight"
                    name="flight"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.flight}
                    autoComplete="off"
                    className={`form-control ${
                      touched.flight && errors.flight ? "form-error" : ""
                    }`}
                    placeholder="Flight Details"
                  />
                  {touched.flight && errors.flight ? (
                    <div className="formErrorMsg">{errors.flight}</div>
                  ) : null}
                </div>
                <div className="formComponentWrapper">
                  <div className="formItem">
                    <input
                      type="text"
                      name="pickUp"
                      id="pickUp"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.pickUp}
                      autoComplete="off"
                      className={`form-control ${
                        touched.pickUp && errors.pickUp ? "form-error" : ""
                      }`}
                      placeholder="Pick Up"
                    />
                    {touched.pickUp && errors.pickUp ? (
                      <div className="formErrorMsg">{errors.pickUp}</div>
                    ) : null}
                  </div>
                  <div className="formItem">
                    <input
                      type="text"
                      id="drop"
                      name="drop"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.drop}
                      autoComplete="off"
                      className={`form-control ${
                        touched.drop && errors.drop ? "form-error" : ""
                      }`}
                      placeholder="Drop"
                    />
                    {touched.drop && errors.drop ? (
                      <div className="formErrorMsg">{errors.drop}</div>
                    ) : null}
                  </div>
                </div>
                <div
                  className="formItem"
                  style={{
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      backgroundColor: "transparent",
                      cursor: "not-allowed",
                      position: "absolute",
                      top: 0,
                      zIndex: "10 ",
                      visibility: edit ? "visible" : "hidden",
                    }}
                  ></div>
                  <select
                    name="confirmedBy"
                    id="confirmedBy"
                    value={values.confirmedBy}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={edit}
                    className={`form-control ${
                      touched.confirmedBy && errors.confirmedBy
                        ? "form-error"
                        : ""
                    }`}
                    style={{
                      backgroundColor: "#fff",
                      color: "#74757a",
                    }}
                  >
                    <option value="confirme by" label="confirmed by" />
                    {userData.map((item, index) => {
                      return (
                        <option
                          value={item.name}
                          label={item.name}
                          key={`${index}`}
                        />
                      );
                    })}
                  </select>
                  {touched.confirmedBy && errors.confirmedBy ? (
                    <div className="formErrorMsg">{errors.confirmedBy}</div>
                  ) : null}
                </div>
                <div className="formItem">
                  <input
                    type="text"
                    name="source"
                    id="source"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.source}
                    autoComplete="off"
                    className={`form-control ${
                      touched.source && errors.source ? "form-error" : ""
                    }`}
                    placeholder="Source"
                  />
                  {touched.source && errors.source ? (
                    <div className="formErrorMsg">{errors.source}</div>
                  ) : null}
                </div>
                <div className="formItem">
                  <input
                    type="text"
                    name="inclusions"
                    id="inclusions"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.inclusions}
                    autoComplete="off"
                    className={`form-control ${
                      touched.inclusions && errors.inclusions
                        ? "form-error"
                        : ""
                    }`}
                    placeholder="Inclusions"
                  />
                  {touched.inclusions && errors.inclusions ? (
                    <div className="formErrorMsg">{errors.inclusions}</div>
                  ) : null}
                </div>
                <div className="formItem">
                  <input
                    type="text"
                    name="roomType"
                    id="roomType"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.roomType}
                    autoComplete="off"
                    disabled={values.cab && !values.both}
                    className={`form-control ${
                      touched.roomType && errors.roomType ? "form-error" : ""
                    }`}
                    placeholder="Room Type"
                  />
                  {touched.roomType && errors.roomType ? (
                    <div className="formErrorMsg">{errors.roomType}</div>
                  ) : null}
                </div>
                <div className="formItem">
                  <div className="formControlWrapper form-control customControl">
                    <label htmlFor="duration" className="custom-label">
                      Duration
                    </label>
                    <label htmlFor="duration" className="custom-label lblMsg">
                      {Math.trunc(
                        (values.departureDate - values.arrivalDate) /
                          (3600 * 24) +
                          1
                      )}{" "}
                      Days
                    </label>
                    <label htmlFor="duration" className="custom-label lblMsg">
                      {Math.trunc(
                        (values.departureDate - values.arrivalDate) /
                          (3600 * 24)
                      )}{" "}
                      Nights
                    </label>
                  </div>
                </div>
                <HotelDetails
                  onEdit={(hotels) =>
                    setFieldValue("stayingDetails", [...hotels])
                  }
                  data={data?.stayingDetails}
                />
                <div className="formComponentWrapper costContainer">
                  <div className="formItem">
                    <input
                      type="number"
                      name="total"
                      id="total"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.total}
                      autoComplete="off"
                      className={`form-control ${
                        touched.total && errors.total ? "form-error" : ""
                      }`}
                      placeholder="Total Amount"
                    />
                    {touched.total && errors.total ? (
                      <div className="formErrorMsg">{errors.total}</div>
                    ) : null}
                  </div>
                  <div className="formItem">
                    <input
                      type="number"
                      id="advance"
                      name="advance"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.advance}
                      autoComplete="off"
                      className={`form-control ${
                        touched.advance && errors.advance ? "form-error" : ""
                      }`}
                      placeholder="Advance"
                    />
                    {touched.advance && errors.advance ? (
                      <div className="formErrorMsg">{errors.advance}</div>
                    ) : null}
                  </div>
                </div>
                <div className="formItem">
                  <div className="formControlWrapper form-control customControl">
                    <label htmlFor="balance" className="custom-label">
                      Balance
                    </label>
                    <label htmlFor="balance" className="custom-label lblMsg">
                      Rs {"    "}
                      {Math.trunc(values.total - values.advance)}
                    </label>
                  </div>
                </div>
                <div className="buttons">
                  <button type="submit" className="primary">
                    {edit ? "Update" : "Submit"}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    ></Formik>
  );
}
export default AddClient;
