import React from "react";
import "./header.css";

function Header() {
  return (
    <div className="headerWrapper">
      <div className="logoContainer">
        <img
          src="https://violetholidays.com/assets/logo.png"
          alt="avatar"
          className="logoImg"
        />
      </div>
      <div className="loginTextContainer">
        <h2 className="loginText">Log in to Violet Holidays</h2>
      </div>
      <div>
        <p className="signInText">Please sign in using your account details.</p>
      </div>
    </div>
  );
}

export default Header;
