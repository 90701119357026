import Modal from "react-modal";
import "./modal.css";
import ClearIcon from "@mui/icons-material/Clear";
import { Link } from "react-router-dom";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    height: "60%",
    width: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function ClientModal(props) {
  return (
    <div>
      <Modal isOpen={props?.isOpen} style={customStyles}>
        <span>
          <ClearIcon
            className="clearIcon"
            style={{
              position: "absolute",
              top: "5px",
              right: "5px",
              fill: "red",
              height: "0.8em",
            }}
            onClick={() => props?.onClose(false)}
          />
        </span>
        <div className="modalContainer">
          <h2 className="modalTitle">{props?.title}</h2>
          <table className="modalTable">
            <tr>
              <th>Name</th>
              <td>{props?.data?.name}</td>
            </tr>
            <tr>
              <th>Mobile Number</th>
              <td>{props?.data?.mobile}</td>
            </tr>
            <tr>
              <th>Trip Id</th>
              <td>{props?.data?.tripId}</td>
            </tr>
            <tr>
              <th>Arrival Date</th>
              <td>
                {new Date(props?.data?.arrivalDate * 1000).toDateString()}
              </td>
            </tr>
            <tr>
              <th>Departure Date</th>
              <td>
                {new Date(props?.data?.departureDate * 1000).toDateString()}
              </td>
            </tr>
            <tr>
              <th>Number of Persons</th>
              <td>{props?.data?.persons}</td>
            </tr>
            {props?.data?.rooms !==null &&
            <tr>
              <th>Number of Rooms</th>
              <td>{props?.data?.rooms}</td>
            </tr>}
            {props?.data?.extraBed ? (
              <tr>
                <th>Extra Bed</th>
                <td>{props?.data?.extraBed}</td>
              </tr>
            ) : (
              ""
            )}
            {props?.data?.cwob ? (
              <tr>
                <th>CWOB</th>
                <td>{props?.data?.cwob}</td>
              </tr>
            ) : (
              ""
            )}
            {props?.data?.mealPlan  &&
            <tr>
              <th>Meal Plan</th>
              <td>{props?.data?.mealPlan}</td>
            </tr>}
            {props?.data?.cab ? (
              <tr>
                <th>Cab</th>
                <td>Yes</td>
              </tr>
            ) : (
              ""
            )}
            {props?.data?.both ? (
              <tr>
                <th>Package & Cab</th>
                <td>Yes</td>
              </tr>
            ) : (
              ""
            )}
            {props?.data?.cabType ? (
              <tr>
                <th>Cab Type</th>
                <td>{props?.data?.cabType}</td>
              </tr>
            ) : (
              ""
            )}
            {props?.data?.driver ? (
              <tr>
                <th>Driver Details</th>
                <td>{props?.data?.driver}</td>
              </tr>
            ) : (
              ""
            )}
            <tr>
              <th>Flight Details</th>
              <td>{props?.data?.flight}</td>
            </tr>
            <tr>
              <th>Pick Up</th>
              <td>{props?.data?.pickUp}</td>
            </tr>
            <tr>
              <th>Drop</th>
              <td>{props?.data?.drop}</td>
            </tr>
            <tr>
              <th>Confirmed By</th>
              <td>{props?.data?.confirmedBy}</td>
            </tr>
            {props?.data?.source ? (
              <tr>
                <th>Source </th>
                <td>{props?.data?.source}</td>
              </tr>
            ) : (
              ""
            )}
            {props.data?.inclusions ? (
              <tr>
                <th>Inclusions </th>
                <td>{props?.data?.inclusions}</td>
              </tr>
            ) : (
              ""
            )}
            {props.data?.roomType ? (
              <tr>
                <th>Room Type </th>
                <td>{props.data?.roomType}</td>
              </tr>
            ) : (
              ""
            )}
          </table>
          {props?.data?.stayingDetails?.length > 0 &&
          <>
          <h2 className="modalTitle">Staying Details</h2>
          <table className="modalTable">
            <tr>
              <th>Date</th>
              <th>Hotel / Houseboat</th>
              <th>Itenary</th>
              <th>Nights</th>
              <th>Confirmed</th>
            </tr>
            {props?.data?.stayingDetails.map((item, i) => {
              return (
                <tr>
                  <td>{item.date.split("T")[0]}</td>
                  <td>{item.hotel}</td>
                  <td>{item.itenary}</td>
                  <td>{item.nights}</td>
                  <td>{item.confirmed}</td>
                </tr>
              );
            })}
          </table></>}
          <table className="modalTable">
            <tr>
              <th>Total</th>
              <td>{props?.data?.total}</td>
            </tr>
            <tr>
              <th>Advance</th>
              <td>{props?.data?.advance}</td>
            </tr>
            <tr>
              <th>Balance</th>
              <td>{Math.floor(props?.data?.total - props?.data?.advance)}</td>
            </tr>
          </table>
        </div>
        <div className="modalButtons">
          <Link
            to={{
              pathname: "/addclient",
              state: { values: props.data, edit: true },
            }}
          >
            <button className="clientEditButton">Edit</button>
          </Link>
        </div>
      </Modal>
    </div>
  );
}
export default ClientModal;
