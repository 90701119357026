import "./header.css";
import "./login.css";
import { useFormik } from "formik";
import * as yup from "yup";
import "./form.css";
import axios from "axios";
import "../client/addClient.css";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const ForgotPassword = () => {
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .email("Invalid Email Address")
        .required("* Field is required"),
    }),
    onSubmit: async (values) => {
      //   console.log(values);
      try {
        const res = await axios.post(
          "https://api.violetholidays.com/forgotpassword",
          {
            ...values,
          }
        );
        // console.log(res.data);
        toast.success(res.data.message);
      } catch (e) {
        // console.log(e.response.data.message);
        toast.error(e.response.data.message, { delay: 0 });
        setTimeout(() => {
          history.pushState("/");
        }, 3000);
      }
      formik.resetForm();
    },
  });

  return (
    <div className="loginWrapper">
      <div className="headerWrapper">
        <div className="logoContainer">
          <img
            src="https://violetholidays.com/images/logo.png"
            alt="avatar"
            className="logoImg"
          />
        </div>
        <div>
          <p className="signInText">Please enter email address.</p>
        </div>
      </div>
      <div className="formWrapper">
        <form onSubmit={formik.handleSubmit}>
          <div className="loginFormContainer">
            <div className="formItem">
              <input
                type="email"
                id="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                autoComplete="off"
                className={`login-form-control  ${
                  formik.touched.email && formik.errors.email
                    ? "form-error"
                    : ""
                }`}
                placeholder="Email"
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="formErrorMsg">{formik.errors.email}</div>
              ) : null}
            </div>
          </div>
          <div className="login-btn">
            <button type="submit" className="primary ">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
